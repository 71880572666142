import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import RouterPage from "./RoutesPage";
function App() {
  
  return (
    <RouterPage/>
  );
}

export default App;
